
import { computed, defineComponent } from 'vue'
import { NavigationGuard } from 'vue-router'

import AccountInfo from '@/components/user/AccountInfo.vue'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { AuthStateEnum } from '@/store/enums/states/auth'
// import { vuexActions } from '@/store/util'

const loadPage: NavigationGuard = async (to, from, next) => {
  // const loadPage: NavigationGuard = async (to, from, next) => {
  // const store = useStore()
  // await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS))
  // next()
  next()
}

export default defineComponent({
  components: {
    AccountInfo
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  setup() {
    const store = useStore()
    const currentUser = computed(() => store.state[ModuleNames.AUTH][AuthStateEnum.USER])
    return {
      currentUser
    }
  }
})

<template>
  <!-- <a-modal
    :closable="!formDisabled"
    :maskClosable="!formDisabled"
    title="Account Info"
    :visible="visible"
    :width="'60%'"
    :footer="null"
    @cancel="onCancel"
    :destroyOnClose="true"
    class="sz-account-info"
  > -->
  <div class="sz-account-info sz-window">
    <div class="sz-title">
      <h1>Account Information</h1>
    </div>
    <div class="sz-account-info-body">
      <a-menu v-model:selectedKeys="selectedMenu" mode="horizontal" class="sz-account-info-menus">
        <a-menu-item :key="MENUS.PROFILE" :disabled="formDisabled">Profile</a-menu-item>
        <a-menu-item :key="MENUS.CHANGE_PASSWORD" :disabled="formDisabled"
          >Change Password</a-menu-item
        >
      </a-menu>
      <div class="sz-account-info-menu-content">
        <template v-if="selectedMenu.includes(MENUS.PROFILE)">
          <a-form
            ref="profileFormRef"
            :label-col="{ span: 8 }"
            :model="profileFormState"
            :wrapper-col="{ span: 16 }"
            class="sz-profile-form"
          >
            <a-form-item
              :wrapper-col="{ span: 16, offset: 8 }"
              :label="null"
              :name="PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME"
              :rules="PROFILE_FORM_RULES[PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]"
            >
              <a-upload
                v-model:fileList="profileFormState[PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]"
                :before-upload="onProfileImageBeforeUpload"
                :disabled="formDisabled"
                :show-upload-list="false"
                accept=".jpeg,.png,.jpg,.gif"
                list-type="picture"
                class="profile-upload-container"
                @change="onProfileImageChange"
              >
                <div class="sz-profile-form-profile-photo-wrapper">
                  <a-avatar
                    :size="100"
                    :src="profileImageUrl"
                    class="sz-profile-form-profile-photo"
                  />
                  <div class="sz-profile-form-profile-photo-controls">
                    <plus-outlined class="sz-profile-form-profile-photo-icon" />
                    <div class="sz-profile-form-profile-photo-text">Upload</div>
                  </div>
                </div>
              </a-upload>
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.USERNAME.LABEL"
              :name="PROFILE_FORM_ITEMS.USERNAME.NAME"
              :rules="PROFILE_FORM_RULES[PROFILE_FORM_ITEMS.USERNAME.NAME]"
            >
              <a-input
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.USERNAME.NAME]"
                :disabled="true"
              />
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.EMAIL.LABEL"
              :name="PROFILE_FORM_ITEMS.EMAIL.NAME"
              :rules="PROFILE_FORM_RULES[PROFILE_FORM_ITEMS.EMAIL.NAME]"
            >
              <a-input
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.EMAIL.NAME]"
                :disabled="formDisabled"
              />
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.TITLE.LABEL"
              :name="PROFILE_FORM_ITEMS.TITLE.NAME"
            >
              <a-input
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.TITLE.NAME]"
                :disabled="formDisabled"
              />
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.FIRST_NAME.LABEL"
              :name="PROFILE_FORM_ITEMS.FIRST_NAME.NAME"
            >
              <a-input
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.FIRST_NAME.NAME]"
                :disabled="formDisabled"
              />
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.LAST_NAME.LABEL"
              :name="PROFILE_FORM_ITEMS.LAST_NAME.NAME"
            >
              <a-input
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.LAST_NAME.NAME]"
                :disabled="formDisabled"
              />
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.PRIMARY_PHONE.LABEL"
              :name="PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME"
              :rules="PROFILE_FORM_RULES[PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]"
            >
              <a-input
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]"
                addon-before="+61"
                :disabled="formDisabled"
              />
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.EXPERTISE.LABEL"
              :name="PROFILE_FORM_ITEMS.EXPERTISE.NAME"
            >
              <a-select
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.EXPERTISE.NAME]"
                :disabled="formDisabled"
                :filter-option="filterExpertiseOption"
                :options="expertiseOptions"
                :show-arrow="false"
                :token-separators="[',']"
                mode="multiple"
                @search="handleExpertiseSearch"
              />
            </a-form-item>
            <a-form-item
              :label="PROFILE_FORM_ITEMS.COMMENTS.LABEL"
              :name="PROFILE_FORM_ITEMS.COMMENTS.NAME"
            >
              <a-textarea
                v-model:value="profileFormState[PROFILE_FORM_ITEMS.COMMENTS.NAME]"
                :disabled="formDisabled"
                auto-size
              />
            </a-form-item>
          </a-form>
          <div class="sz-command-footer">
            <a-button type="primary" size="small" :loading="formDisabled" @click="onProfileUpdate">
              Update
            </a-button>
          </div>
        </template>
        <template v-if="selectedMenu.includes(MENUS.CHANGE_PASSWORD)">
          <a-form
            ref="changePasswordFormRef"
            :label-col="{ span: 8 }"
            :model="changePasswordFormState"
            :wrapper-col="{ span: 16 }"
            class="sz-change-password-form"
          >
            <a-form-item
              :label="CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.LABEL"
              :name="CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.NAME"
              :rules="CHANGE_PASSWORD_FORM_RULES[CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.NAME]"
            >
              <a-input-password
                v-model:value="
                  changePasswordFormState[CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.NAME]
                "
                :disabled="formDisabled"
              />
            </a-form-item>
            <a-form-item
              :label="CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.LABEL"
              :name="CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.NAME"
              :rules="CHANGE_PASSWORD_FORM_RULES[CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.NAME]"
            >
              <a-input-password
                v-model:value="
                  changePasswordFormState[CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.NAME]
                "
                :disabled="formDisabled"
              />
            </a-form-item>
          </a-form>
          <div class="sz-command-footer">
            <a-button type="primary" size="small" :loading="formDisabled" @click="onPasswordUpdate">
              Update
            </a-button>
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- </a-modal> -->
</template>

<script lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { clone } from 'ramda'
import { computed, defineComponent, PropType, reactive, Ref, ref, toRaw, watch } from 'vue'

import { DEFAULT_PROFILE_PHOTO_URL } from '@/constants/components'
import { DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { maxFileSize } from '@/libs/formValidate'
import { filterExpertiseOption, genExpertiseOptions, getBase64 } from '@/libs/utils'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { vuexActions } from '@/store/util'
import { User, UserForm, ValueOf } from '@/types'

interface FileInfo {
  file: File
  fileList: File[]
}

type ProfileFormState = Omit<UserForm, 'profilePhoto' | 'password' | 'enabled' | 'roles'> & {
  [DB_FIELDS.USER.PROFILE_PHOTO]: File[]
}
export const EVENTS = {
  SET_VISIBLE: 'setVisible'
} as const

const PROFILE_FORM_ITEMS = {
  USERNAME: {
    NAME: DB_FIELDS.USER.USERNAME,
    LABEL: 'Username'
  },
  EMAIL: {
    NAME: DB_FIELDS.USER.EMAIL,
    LABEL: 'Email'
  },
  TITLE: {
    NAME: DB_FIELDS.USER.TITLE,
    LABEL: 'Title'
  },
  FIRST_NAME: {
    NAME: DB_FIELDS.USER.FIRST_NAME,
    LABEL: 'First Name'
  },
  LAST_NAME: {
    NAME: DB_FIELDS.USER.LAST_NAME,
    LABEL: 'Last Name'
  },
  PROFILE_PHOTO: {
    NAME: DB_FIELDS.USER.PROFILE_PHOTO,
    LABEL: 'Profile Photo'
  },
  PRIMARY_PHONE: {
    NAME: DB_FIELDS.USER.PRIMARY_PHONE,
    LABEL: 'Primary Phone'
  },
  EXPERTISE: {
    NAME: DB_FIELDS.USER.EXPERTISE,
    LABEL: 'Expertise'
  },
  COMMENTS: {
    NAME: DB_FIELDS.USER.COMMENTS,
    LABEL: 'Comments'
  }
} as const

export const CHANGE_PASSWORD_FORM_ITEMS = {
  OLD_PASSWORD: {
    NAME: 'oldPassword',
    LABEL: 'Old Password'
  },
  NEW_PASSWORD: {
    NAME: 'newPassword',
    LABEL: 'New Password'
  }
} as const

const DEFAULT_PROFILE_FORM_STATE: ProfileFormState = {
  [DB_FIELDS.USER.EXT]: {},
  [PROFILE_FORM_ITEMS.USERNAME.NAME]: '',
  [PROFILE_FORM_ITEMS.EMAIL.NAME]: '',
  [PROFILE_FORM_ITEMS.TITLE.NAME]: null,
  [PROFILE_FORM_ITEMS.FIRST_NAME.NAME]: null,
  [PROFILE_FORM_ITEMS.LAST_NAME.NAME]: null,
  [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: [],
  [PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]: null,
  [PROFILE_FORM_ITEMS.EXPERTISE.NAME]: [],
  [PROFILE_FORM_ITEMS.COMMENTS.NAME]: null
}

const DEFAULT_CHANGE_PASSWORD_FORM_STATE = {
  [CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.NAME]: '',
  [CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.NAME]: ''
}

const PROFILE_FORM_RULES = {
  [PROFILE_FORM_ITEMS.USERNAME.NAME]: [
    {
      required: true,
      message: 'Please enter the username'
    }
  ],
  [PROFILE_FORM_ITEMS.EMAIL.NAME]: [
    {
      required: true,
      message: 'Please enter the email'
    },
    {
      message: 'Please enter a valid email',
      type: 'email',
      trigger: 'blur'
    }
  ],
  [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: [
    {
      validator: maxFileSize({
        maxSize: 100 * 1024,
        errorMessage: 'Maxium file size is 100Kb',
        multiple: false
      })
    }
  ],
  [PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]: [
    {
      pattern: new RegExp('^[2-478](?:[ -]?[0-9]){8}$'),
      message: 'Please enter a valid phone number',
      trigger: 'blur',
      type: 'string'
    }
  ]
} as const

const CHANGE_PASSWORD_FORM_RULES = {
  [CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.NAME]: {
    required: true,
    message: 'Please enter the old password'
  },
  [CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.NAME]: {
    required: true,
    message: 'Please enter the old password'
  }
}

const MENUS = {
  PROFILE: 'PROFILE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD'
} as const

const initProfileFormData = (user: User) =>
  Object.assign(clone(DEFAULT_PROFILE_FORM_STATE), {
    [DB_FIELDS.USER.EXT]: user[DB_FIELDS.USER.EXT],
    [PROFILE_FORM_ITEMS.USERNAME.NAME]: user[DB_FIELDS.USER.USERNAME],
    [PROFILE_FORM_ITEMS.EMAIL.NAME]: user[DB_FIELDS.USER.EMAIL],
    [PROFILE_FORM_ITEMS.TITLE.NAME]: user[DB_FIELDS.USER.TITLE],
    [PROFILE_FORM_ITEMS.FIRST_NAME.NAME]: user[DB_FIELDS.USER.FIRST_NAME],
    [PROFILE_FORM_ITEMS.LAST_NAME.NAME]: user[DB_FIELDS.USER.LAST_NAME],
    [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: [],
    [PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]:
      user[DB_FIELDS.USER.PRIMARY_PHONE] ||
      DEFAULT_PROFILE_FORM_STATE[PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME],
    [PROFILE_FORM_ITEMS.EXPERTISE.NAME]:
      user[DB_FIELDS.USER.EXPERTISE] ||
      DEFAULT_PROFILE_FORM_STATE[PROFILE_FORM_ITEMS.EXPERTISE.NAME],
    [PROFILE_FORM_ITEMS.COMMENTS.NAME]:
      user[DB_FIELDS.USER.COMMENTS] || DEFAULT_PROFILE_FORM_STATE[PROFILE_FORM_ITEMS.COMMENTS.NAME]
  })

export default defineComponent({
  components: {
    PlusOutlined
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const isCreate = computed(() => !props.user)
    const profileFormRef = ref()
    const changePasswordFormRef = ref()
    const formDisabled = ref<boolean>(false)
    const profileFormState = reactive(initProfileFormData(props.user))
    const changePasswordFormState = reactive(Object.assign({}, DEFAULT_CHANGE_PASSWORD_FORM_STATE))
    const profileImageUrl = ref(
      props.user?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
    )
    const selectedMenu = ref<ValueOf<typeof MENUS>[]>([MENUS.PROFILE])
    const expertiseSearchText: Ref<string | null> = ref('')
    const expertiseOptions = computed(() =>
      genExpertiseOptions(
        profileFormState[PROFILE_FORM_ITEMS.EXPERTISE.NAME],
        expertiseSearchText.value
      )
    )

    const onProfileImageBeforeUpload = () => false
    const onProfileImageChange = async (info: FileInfo) => {
      try {
        const { file } = info
        const url = await getBase64(file)
        profileImageUrl.value = url
      } catch (err) {
        const errorMessage = err.message || 'Failed to read image.'
        message.error(errorMessage)
      }
    }
    const onCancel = () => {
      emit(EVENTS.SET_VISIBLE, false)
    }

    const onProfileUpdate = async () => {
      formDisabled.value = true
      if (profileFormRef.value) {
        try {
          await profileFormRef.value.validate()
        } catch (err) {
          formDisabled.value = false
          const firstErrorFieldName =
            err.errorFields[0].name.length > 1 ? [err.errorFields[0].name] : err.errorFields[0].name
          profileFormRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
          return
        }
      }
      let data = {} as Partial<User>
      // update profile photo field with base64 url
      if (profileFormState[PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME].length > 0) {
        data = Object.assign({}, toRaw(profileFormState), {
          [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: profileImageUrl.value
        })
      } else {
        data = Object.assign({}, toRaw(profileFormState), {
          [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: null
        })
      }
      try {
        await store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.UPDATE_PROFILE), data)
        message.success(MESSAGE.PROFILE_UPDATED_SUCCESS)
      } catch (err) {
        formDisabled.value = false
        return
      }
      formDisabled.value = false
    }

    const onPasswordUpdate = async () => {
      formDisabled.value = true
      if (changePasswordFormRef.value) {
        try {
          await changePasswordFormRef.value.validate()
        } catch (err) {
          formDisabled.value = false
          const firstErrorFieldName =
            err.errorFields[0].name.length > 1 ? [err.errorFields[0].name] : err.errorFields[0].name
          changePasswordFormRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
          return
        }
      }
      const data = toRaw(changePasswordFormState)
      try {
        await store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.UPDATE_PASSWORD), data)
        message.success(MESSAGE.PASSWORD_CHANGED_SUCCESS)
      } catch (err) {
        formDisabled.value = false
        return
      }
      formDisabled.value = false
    }

    const handleExpertiseSearch = (value: string) => {
      expertiseSearchText.value = value
    }

    watch(
      () => props.user,
      () => {
        const newFormState = initProfileFormData(props.user)
        Object.assign(profileFormState, newFormState)
        profileImageUrl.value =
          props.user?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
        Object.assign(changePasswordFormState, DEFAULT_CHANGE_PASSWORD_FORM_STATE)
      }
    )

    return {
      CHANGE_PASSWORD_FORM_ITEMS,
      CHANGE_PASSWORD_FORM_RULES,
      MENUS,
      PROFILE_FORM_ITEMS,
      PROFILE_FORM_RULES,
      changePasswordFormRef,
      changePasswordFormState,
      expertiseOptions,
      formDisabled,
      handleExpertiseSearch,
      isCreate,
      onCancel,
      onPasswordUpdate,
      onProfileImageBeforeUpload,
      onProfileImageChange,
      onProfileUpdate,
      profileFormRef,
      profileFormState,
      profileImageUrl,
      selectedMenu,
      filterExpertiseOption
    }
  }
})
</script>

<style lang="stylus">
@import '../../styles/base.styl';

.sz-account-info
  max-height calc(100vh - 80px) // minus ((modal top + bottom) + header)
  max-width 600px
  overflow hidden
  display flex
  padding 10px 0 0 0
  flex-direction column
  position: relative

  .sz-account-info-body
    display flex
    overflow auto
    flex-direction column

  .sz-account-info-menus
    width 100%
    &.ant-menu-horizontal
      line-height 32px
    .ant-menu-item-selected
     color #5890af
     background-color #e1edf0
    .ant-menu-item::after
      border-right-color #5890af
    .ant-menu-item:hover
      color #5890af

  .sz-account-info-menu-content
    overflow hidden
    padding 10px 0 0 0
    position relative

    .ant-form-item-control
      max-width 80%

    .sz-profile-form, .sz-change-password-form
      max-height calc(100% - 34px)
      overflow auto
      padding-right 10px
      // width 100%

      .ant-row
        margin-bottom 5px

    .sz-profile-form
      .sz-profile-form-username-input
        width 60%
        margin-right 10px

      .profile-upload-container
        display flex
        .sz-profile-form-profile-photo-wrapper
          margin 6px 0
          display inline-block
          position relative
          border: 1px solid #d9d9d9;

          &:before
            background-color black
            position:absolute
            z-index 1
            width 100%
            height 100%
            background-color rgba(0, 0, 0, 0.5)
            opacity 0
            -webkit-transition all 0.3s
            transition all 0.3s
            content ' '
            cursor pointer

          &:hover
            &:before
              opacity 0.4

            .sz-profile-form-profile-photo-controls
              display flex

          .sz-profile-form-profile-photo
            margin: 10px;
            cursor: pointer;

          .sz-profile-form-profile-photo-controls
            display none
            position absolute
            top: 50%
            left: 50%
            transform translate(-50%, -50%)
            color white
            font-size 24px
            z-index 1000
            cursor pointer
            justifiy-content center
            align-items center
            .sz-profile-form-profile-photo-icon
              font-size 18px
              margin-right 2px
            .sz-profile-form-profile-photo-text
              font-size 20px
</style>

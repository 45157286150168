
import { PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { clone } from 'ramda'
import { computed, defineComponent, PropType, reactive, Ref, ref, toRaw, watch } from 'vue'

import { DEFAULT_PROFILE_PHOTO_URL } from '@/constants/components'
import { DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { maxFileSize } from '@/libs/formValidate'
import { filterExpertiseOption, genExpertiseOptions, getBase64 } from '@/libs/utils'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { vuexActions } from '@/store/util'
import { User, UserForm, ValueOf } from '@/types'

interface FileInfo {
  file: File
  fileList: File[]
}

type ProfileFormState = Omit<UserForm, 'profilePhoto' | 'password' | 'enabled' | 'roles'> & {
  [DB_FIELDS.USER.PROFILE_PHOTO]: File[]
}
export const EVENTS = {
  SET_VISIBLE: 'setVisible'
} as const

const PROFILE_FORM_ITEMS = {
  USERNAME: {
    NAME: DB_FIELDS.USER.USERNAME,
    LABEL: 'Username'
  },
  EMAIL: {
    NAME: DB_FIELDS.USER.EMAIL,
    LABEL: 'Email'
  },
  TITLE: {
    NAME: DB_FIELDS.USER.TITLE,
    LABEL: 'Title'
  },
  FIRST_NAME: {
    NAME: DB_FIELDS.USER.FIRST_NAME,
    LABEL: 'First Name'
  },
  LAST_NAME: {
    NAME: DB_FIELDS.USER.LAST_NAME,
    LABEL: 'Last Name'
  },
  PROFILE_PHOTO: {
    NAME: DB_FIELDS.USER.PROFILE_PHOTO,
    LABEL: 'Profile Photo'
  },
  PRIMARY_PHONE: {
    NAME: DB_FIELDS.USER.PRIMARY_PHONE,
    LABEL: 'Primary Phone'
  },
  EXPERTISE: {
    NAME: DB_FIELDS.USER.EXPERTISE,
    LABEL: 'Expertise'
  },
  COMMENTS: {
    NAME: DB_FIELDS.USER.COMMENTS,
    LABEL: 'Comments'
  }
} as const

export const CHANGE_PASSWORD_FORM_ITEMS = {
  OLD_PASSWORD: {
    NAME: 'oldPassword',
    LABEL: 'Old Password'
  },
  NEW_PASSWORD: {
    NAME: 'newPassword',
    LABEL: 'New Password'
  }
} as const

const DEFAULT_PROFILE_FORM_STATE: ProfileFormState = {
  [DB_FIELDS.USER.EXT]: {},
  [PROFILE_FORM_ITEMS.USERNAME.NAME]: '',
  [PROFILE_FORM_ITEMS.EMAIL.NAME]: '',
  [PROFILE_FORM_ITEMS.TITLE.NAME]: null,
  [PROFILE_FORM_ITEMS.FIRST_NAME.NAME]: null,
  [PROFILE_FORM_ITEMS.LAST_NAME.NAME]: null,
  [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: [],
  [PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]: null,
  [PROFILE_FORM_ITEMS.EXPERTISE.NAME]: [],
  [PROFILE_FORM_ITEMS.COMMENTS.NAME]: null
}

const DEFAULT_CHANGE_PASSWORD_FORM_STATE = {
  [CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.NAME]: '',
  [CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.NAME]: ''
}

const PROFILE_FORM_RULES = {
  [PROFILE_FORM_ITEMS.USERNAME.NAME]: [
    {
      required: true,
      message: 'Please enter the username'
    }
  ],
  [PROFILE_FORM_ITEMS.EMAIL.NAME]: [
    {
      required: true,
      message: 'Please enter the email'
    },
    {
      message: 'Please enter a valid email',
      type: 'email',
      trigger: 'blur'
    }
  ],
  [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: [
    {
      validator: maxFileSize({
        maxSize: 100 * 1024,
        errorMessage: 'Maxium file size is 100Kb',
        multiple: false
      })
    }
  ],
  [PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]: [
    {
      pattern: new RegExp('^[2-478](?:[ -]?[0-9]){8}$'),
      message: 'Please enter a valid phone number',
      trigger: 'blur',
      type: 'string'
    }
  ]
} as const

const CHANGE_PASSWORD_FORM_RULES = {
  [CHANGE_PASSWORD_FORM_ITEMS.OLD_PASSWORD.NAME]: {
    required: true,
    message: 'Please enter the old password'
  },
  [CHANGE_PASSWORD_FORM_ITEMS.NEW_PASSWORD.NAME]: {
    required: true,
    message: 'Please enter the old password'
  }
}

const MENUS = {
  PROFILE: 'PROFILE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD'
} as const

const initProfileFormData = (user: User) =>
  Object.assign(clone(DEFAULT_PROFILE_FORM_STATE), {
    [DB_FIELDS.USER.EXT]: user[DB_FIELDS.USER.EXT],
    [PROFILE_FORM_ITEMS.USERNAME.NAME]: user[DB_FIELDS.USER.USERNAME],
    [PROFILE_FORM_ITEMS.EMAIL.NAME]: user[DB_FIELDS.USER.EMAIL],
    [PROFILE_FORM_ITEMS.TITLE.NAME]: user[DB_FIELDS.USER.TITLE],
    [PROFILE_FORM_ITEMS.FIRST_NAME.NAME]: user[DB_FIELDS.USER.FIRST_NAME],
    [PROFILE_FORM_ITEMS.LAST_NAME.NAME]: user[DB_FIELDS.USER.LAST_NAME],
    [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: [],
    [PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME]:
      user[DB_FIELDS.USER.PRIMARY_PHONE] ||
      DEFAULT_PROFILE_FORM_STATE[PROFILE_FORM_ITEMS.PRIMARY_PHONE.NAME],
    [PROFILE_FORM_ITEMS.EXPERTISE.NAME]:
      user[DB_FIELDS.USER.EXPERTISE] ||
      DEFAULT_PROFILE_FORM_STATE[PROFILE_FORM_ITEMS.EXPERTISE.NAME],
    [PROFILE_FORM_ITEMS.COMMENTS.NAME]:
      user[DB_FIELDS.USER.COMMENTS] || DEFAULT_PROFILE_FORM_STATE[PROFILE_FORM_ITEMS.COMMENTS.NAME]
  })

export default defineComponent({
  components: {
    PlusOutlined
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const isCreate = computed(() => !props.user)
    const profileFormRef = ref()
    const changePasswordFormRef = ref()
    const formDisabled = ref<boolean>(false)
    const profileFormState = reactive(initProfileFormData(props.user))
    const changePasswordFormState = reactive(Object.assign({}, DEFAULT_CHANGE_PASSWORD_FORM_STATE))
    const profileImageUrl = ref(
      props.user?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
    )
    const selectedMenu = ref<ValueOf<typeof MENUS>[]>([MENUS.PROFILE])
    const expertiseSearchText: Ref<string | null> = ref('')
    const expertiseOptions = computed(() =>
      genExpertiseOptions(
        profileFormState[PROFILE_FORM_ITEMS.EXPERTISE.NAME],
        expertiseSearchText.value
      )
    )

    const onProfileImageBeforeUpload = () => false
    const onProfileImageChange = async (info: FileInfo) => {
      try {
        const { file } = info
        const url = await getBase64(file)
        profileImageUrl.value = url
      } catch (err) {
        const errorMessage = err.message || 'Failed to read image.'
        message.error(errorMessage)
      }
    }
    const onCancel = () => {
      emit(EVENTS.SET_VISIBLE, false)
    }

    const onProfileUpdate = async () => {
      formDisabled.value = true
      if (profileFormRef.value) {
        try {
          await profileFormRef.value.validate()
        } catch (err) {
          formDisabled.value = false
          const firstErrorFieldName =
            err.errorFields[0].name.length > 1 ? [err.errorFields[0].name] : err.errorFields[0].name
          profileFormRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
          return
        }
      }
      let data = {} as Partial<User>
      // update profile photo field with base64 url
      if (profileFormState[PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME].length > 0) {
        data = Object.assign({}, toRaw(profileFormState), {
          [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: profileImageUrl.value
        })
      } else {
        data = Object.assign({}, toRaw(profileFormState), {
          [PROFILE_FORM_ITEMS.PROFILE_PHOTO.NAME]: null
        })
      }
      try {
        await store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.UPDATE_PROFILE), data)
        message.success(MESSAGE.PROFILE_UPDATED_SUCCESS)
      } catch (err) {
        formDisabled.value = false
        return
      }
      formDisabled.value = false
    }

    const onPasswordUpdate = async () => {
      formDisabled.value = true
      if (changePasswordFormRef.value) {
        try {
          await changePasswordFormRef.value.validate()
        } catch (err) {
          formDisabled.value = false
          const firstErrorFieldName =
            err.errorFields[0].name.length > 1 ? [err.errorFields[0].name] : err.errorFields[0].name
          changePasswordFormRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
          return
        }
      }
      const data = toRaw(changePasswordFormState)
      try {
        await store.dispatch(vuexActions(ModuleNames.AUTH, AuthActionEnum.UPDATE_PASSWORD), data)
        message.success(MESSAGE.PASSWORD_CHANGED_SUCCESS)
      } catch (err) {
        formDisabled.value = false
        return
      }
      formDisabled.value = false
    }

    const handleExpertiseSearch = (value: string) => {
      expertiseSearchText.value = value
    }

    watch(
      () => props.user,
      () => {
        const newFormState = initProfileFormData(props.user)
        Object.assign(profileFormState, newFormState)
        profileImageUrl.value =
          props.user?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
        Object.assign(changePasswordFormState, DEFAULT_CHANGE_PASSWORD_FORM_STATE)
      }
    )

    return {
      CHANGE_PASSWORD_FORM_ITEMS,
      CHANGE_PASSWORD_FORM_RULES,
      MENUS,
      PROFILE_FORM_ITEMS,
      PROFILE_FORM_RULES,
      changePasswordFormRef,
      changePasswordFormState,
      expertiseOptions,
      formDisabled,
      handleExpertiseSearch,
      isCreate,
      onCancel,
      onPasswordUpdate,
      onProfileImageBeforeUpload,
      onProfileImageChange,
      onProfileUpdate,
      profileFormRef,
      profileFormState,
      profileImageUrl,
      selectedMenu,
      filterExpertiseOption
    }
  }
})

import { render } from "./AccountInfo.vue?vue&type=template&id=0fb5e6b2"
import script from "./AccountInfo.vue?vue&type=script&lang=ts"
export * from "./AccountInfo.vue?vue&type=script&lang=ts"

import "./AccountInfo.vue?vue&type=style&index=0&id=0fb5e6b2&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0fb5e6b2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0fb5e6b2', script)) {
    api.reload('0fb5e6b2', script)
  }
  
  module.hot.accept("./AccountInfo.vue?vue&type=template&id=0fb5e6b2", () => {
    api.rerender('0fb5e6b2', render)
  })

}

script.__file = "src/components/user/AccountInfo.vue"

export default script